<template>
  <label v-bind:for="component_meta.variable" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <select v-bind:id="component_meta.variable" v-bind:name="component_meta.variable" v-model="selectedOption"
    @change="component_select_change" class="form-control component-list">
    <template v-for="(item, key) in component_meta_list" :key="key">
      <option v-bind:value="key">
        {{ item.title }}
      </option>
    </template>
  </select>
  <button type="button" class="btn btn-default btn-sm bg-blue" @click="show_property_dialog">...</button>
  <div class="modal fade" v-bind:id="modal_id">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">元件屬性設定</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div v-for="field_meta in selected_meta" :key="field_meta.variable" v-bind:class="[
              'form-group', 'col-' + field_meta.component.properties.col_width.mobile,
              'col-sm-' + field_meta.component.properties.col_width.pad,
              'col-md-' + field_meta.component.properties.col_width.pad,
              'col-lg-' + field_meta.component.properties.col_width.desktop]">
              <component v-bind:is="field_meta._component" v-bind:field="field_meta"
                v-bind:value="component_value.properties[field_meta.variable]"
                @input="update_dialog_form_value(field_meta, $event)" :key="field_meta.variable">
              </component>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import $ from 'jquery'
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    // 預設值 及 初始化

    // 從後端載入所有元件的 Meta 資料
    let api_url = this.$microlink.base_api_url + this.field.component.properties.load_comonents_metadata;
    let component_meta_list = this.$microlink.api(api_url);

    // 元件下拉選單的資料(從前端的 list.js 載入)
    let component_select = this.$microlink.components;
    for (let key in component_select) {

      // 如果前端的元件清單為空的或後端的元件清單為空的，就不要呈現出來
      if (component_select[key] == undefined || component_meta_list[key] == undefined) {
        continue;
      }

      // 讓元件下拉選單出現的名稱，以後端的元件名稱為主
      component_select[key].title = component_meta_list[key].title;
    }

    // 清單最前面，加上請選擇
    component_select = { '': { 'title': '請選擇' }, ...component_select };

    // 值的初始化
    let localValue = this.value;


    return {

      component_id: 'component-select-' + Math.random().toString().substring(2),

      // 這個元件的值
      component_value: localValue,

      component_meta: this.field,

      // 元件清單，清單中每個項目都包含該元件所需的屬性 metadata
      component_select: component_select,

      // 元件Meta資料集
      component_meta_list: component_meta_list,

      // 從元件下拉中，被選擇到的元件的 metadata
      selected_meta: {},

      // 元件下拉選單所選到的索引值
      selectedOption: (this.value.type != undefined) ? this.value.type : '',

      // 屬性小視窗的識別碼
      modal_id: 'component-select-modal-' + Math.random().toString().substring(2)
    }
  },
  methods: {

    // 當屬性小視窗內的值被改變
    update_dialog_form_value: function (meta, $event) {
      if (this.component_value == undefined) {
        this.component_value = { 'type': '', 'properties': {} };
        $event.temp = { 'type': '', 'properties': {} };
      }

      this.component_value.properties[meta.variable] = ($event.temp == undefined) ? $event.target.value : $event.temp;
      $event.temp = this.component_value;

      this.$emit('input', $event);
    },

    // 當元件清單被改變的時候
    component_select_change: function ($event) {
      if (this.component_value == undefined || this.component_value == '') {
        this.component_value = { 'type': '', 'properties': {} };
      }

      this.load_component_properties();

      this.component_value.type = $event.target.value;
      $event.temp = this.component_value;
      this.$emit('input', $event);
    },

    // 載入元件的屬性
    load_component_properties: function () {
      if (this.selectedOption == '') {
        return;
      }

      if (this.component_value.properties == undefined) {
        this.component_value.properties = {};
      }
      this.selected_meta = this.component_meta_list[this.selectedOption].fields;
      if (this.selected_meta.length > 0) {
        for (let key in this.selected_meta) {
          this.selected_meta[key]._component = this.$microlink.components[this.selected_meta[key].component.type].component;
          if (this.component_value.properties[this.selected_meta[key].variable] == undefined) {
            this.component_value.properties[this.selected_meta[key].variable] = this.selected_meta[key].component.properties.default;
          }
        }
      }
    },

    // 開啟屬性小視窗
    show_property_dialog: function () {
      this.load_component_properties();
      $('#' + this.modal_id).modal('show');
    }
  }
}
</script>

<style scoped>
select.component-list {
  width: 70%;
  display: inline;
}

button {
  margin-left: 3px;
  display: inline;
}

span.tip {
  color: green;
}
</style>